<template>
  <v-app>
    <v-main>
      <transition name="page" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    data: () => ({
      //
    }),
    beforeMount() {
      // let vh = window.innerHeight * 0.01;
      // document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  };
</script>