import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import "./assets/scss/main.scss";
import i18n from "./plugins/i18n/i18n";
import VueCookie from "vue-cookies";
import moment from 'moment-timezone'
import "material-design-icons-iconfont";
import mixin from "./mixins"

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  let url = config.url.split('/')[2];
  if (url == undefined) {
    config.headers["X-API-KEY"] = 'CaSaAPIffc63Ur13PvSHOfC1jr3CSqX5c6OFCASA'
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.defaults.baseURL = 'https://app-api.casalapin.com';
// axios.defaults.baseURL = 'https://app-uat.casalapin.com';
//axios.defaults.baseURL = 'https://dev-casa-api.extream.in';
Vue.prototype.$axios = axios;

moment().tz("Asia/Bangkok").format();
Vue.config.productionTip = false;
Vue.prototype.$moment = moment
Vue.use(VueCookie);
window.$cookies.config("30d");
Vue.mixin(mixin)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");