import loggedIn from "../middleware/loggedIn";
import guest from "../middleware/guest";
export default [
  {
    path: '*',
    component: () => import(`@/views/notfound.vue`),
  },
  {
    path: '/thankPage',
    name: "thankPage",
    component: () => import(`@/views/thankPage.vue`),
  },
  {
    path: "/loadingScreen",
    name: "LoadingScreen",
    component: () => import(`@/views/LoadingScreen.vue`),
  },
  {
    path: "/SelectLanguage",
    name: "SelectLanguage",
    component: () => import(`@/views/SelectLanguage.vue`),
    beforeEnter: loggedIn,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/PhoneVerify",
    name: "PhoneVerify",
    component: () => import(`@/views/OTP/phoneVerify.vue`),
    beforeEnter: loggedIn,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/OtpVerify",
    name: "OtpVerify",
    component: () => import(`@/views/OTP/otpVerify.vue`),
    beforeEnter: loggedIn,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import(`@/views/Home.vue`),
    beforeEnter: loggedIn
  },
  {
    path: "/News",
    name: "News",
    component: () => import(`@/views/News`),
    beforeEnter: loggedIn
  },
  {
    path: "/NewsDetail/:id",
    name: "NewsDetail",
    component: () => import(`@/views/News/Detail.vue`),
    beforeEnter: loggedIn
  },
  {
    path: "/History",
    name: "History",
    component: () => import(`@/views/History`),
    beforeEnter: loggedIn
  },
  {
    path: "/Receipt/:id",
    name: "Receipt",
    component: () => import(`@/views/History/receipt.vue`),
    beforeEnter: loggedIn
  },
  {
    path: "/Setting",
    name: "Setting",
    component: () => import(`@/views/Setting`),
    beforeEnter: loggedIn
  },
  {
    path: "/SetLanguage",
    name: "SetLanguage",
    component: () => import(`@/views/Setting/setLanguage.vue`),
    beforeEnter: loggedIn
  },
  {
    path: "/Purchases",
    name: "Purchases",
    component: () => import(`@/views/Purchase`),
    beforeEnter: loggedIn
  },
  {
    path: "/Purchases/Detail/:type/:id",
    name: "PurchasesDetail",
    component: () => import(`@/views/Purchase/detail`),
    beforeEnter: loggedIn
  },
  {
    path: "/Purchases/Used/:type/:id",
    name: "PurchasesUsed",
    component: () => import(`@/views/Purchase/used`),
    beforeEnter: loggedIn
  },
  {
    path: "/store/:id",
    name: "StoreDetail",
    component: () => import(`@/views/Store/detail`),
    beforeEnter: loggedIn
  },
  {
    path: "/PaymentDetail/:id",
    name: "PaymentDetail",
    component: () => import(`@/views/Store/payment_detail`),
    beforeEnter: loggedIn
  },
  {
    path: "/PaymentConfirm/:id",
    name: "PaymentConfirm",
    component: () => import(`@/views/Store/payment_confirm`),
    beforeEnter: loggedIn
  },
  {
    path: "/PaymentKbank/:id",
    name: "PaymentKbank",
    component: () => import(`@/views/Store/payment_kbank`),
    beforeEnter: loggedIn
  },
  {
    path: "/form_pay_kbank",
    name: "FormPayKbank",
    component: () => import(`@/views/Store/form_pay_kbank`),
    beforeEnter: loggedIn
  },
  {
    path: "/Information",
    name: "information",
    component: () => import(`@/views/information`),
    beforeEnter: loggedIn
  },
]
