import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
import moduleAuth from "./module/auth";
import moduleStore from "./module/store";
export default new Vuex.Store({
    modules: {
      moduleAuth: moduleAuth,
      moduleStore: moduleStore
    }
  })

