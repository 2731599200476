import store from "../store";

export default function loggedIn( to, from, next ) {
  const login = store.state.moduleAuth.loggedIn
  if (login) {
     return next();
  } 
  return next('/loadingScreen');
}



