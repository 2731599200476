import $axios from "axios";
export default {
  state: {
    coupons: [],
    all_coupon_codes: [],
    history: [],
  },
  getters: {
    couponById: (state) => (id) => {
      return state.coupons.find((q) => q.objectId == id);
    },
    coupon_codesById: (state) => (id) => {
      return state.all_coupon_codes.find((q) => q.objectId == id);
    },
    coupon_codes(state) {
      return state.all_coupon_codes.filter(
        (q) => q.coupon_list_id.type == "sale" && (!q.is_used || q.is_used == undefined)
      );
    },
    reward_codes(state) {
      return state.all_coupon_codes.filter(
        (q) => q.coupon_list_id.type == "reward" && (!q.is_used || q.is_used == undefined)
      );
    },
    used_codes(state) {
      return state.all_coupon_codes.filter((q) => q.is_used);
    },
    historyById: (state) => (id) => {
      return state.history.find((q) => q.objectId == id);
    },
  },
  mutations: {
    SET_COUPONS(state, data) {
      state.coupons = data;
    },
    SET_COUPON_CODES(state, data) {
      state.all_coupon_codes = data;
    },
    SET_HISTORY(state, data) {
      state.history = data;
    },
  },
  actions: {
    async get_coupons({ commit }) {
      try {
        const config = {
          headers : {
            'x-parse-session-token': localStorage.getItem('sessionToken_casalapin'),
          }
        }
        let response = await $axios.post( "/get_coupons","",config);
        return await commit("SET_COUPONS", response.data.data);
      } catch (err) {}
    },
    async get_coupon_codes({ commit }) {
      try {
        const config = {
          headers : {
            'x-parse-session-token': localStorage.getItem('sessionToken_casalapin'),
          }
        }
        let response = await $axios.post( "/get_coupon_codes","",config);
        return await commit("SET_COUPON_CODES", response.data.data);
      } catch (err) {}
    },
    async get_coupon_purchase_history({ commit }) {
      try {
        const config = {
          headers : {
            'x-parse-session-token': localStorage.getItem('sessionToken_casalapin')
          }
        }
        let response = await $axios.post( "/get_coupon_purchase_history","",config);
        return await commit("SET_HISTORY", response.data.data);
      } catch (err) {}
    },
  },
};
