const mixins = {
    methods: {
        formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(
                    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
                ).toString();
                let j = i.length > 3 ? i.length % 3 : 0;

                return (
                    negativeSign +
                    (j ? i.substr(0, j) + thousands : "") +
                    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
                    (decimalCount ?
                        decimal +
                        Math.abs(amount - i)
                        .toFixed(decimalCount)
                        .slice(2) :
                        "")
                );
            } catch (e) {
                console.log(e);
            }
        },
        DateTime(stmpDate) {
            let d = this.$moment(stmpDate);
            var month = new Array();
            month[0] = this.$i18n.locale == 'th' ? "มกราคม" : "January";
            month[1] = this.$i18n.locale == 'th' ? "กุมภาพันธุ์" : "February";
            month[2] = this.$i18n.locale == 'th' ? "มีนาคม" : "March";
            month[3] = this.$i18n.locale == 'th' ? "เมษายน" : "April";
            month[4] = this.$i18n.locale == 'th' ? "พฤษภาคม" : "May";
            month[5] = this.$i18n.locale == 'th' ? "มิถุนายน" : "June";
            month[6] = this.$i18n.locale == 'th' ? "กรกฎาคม" : "July";
            month[7] = this.$i18n.locale == 'th' ? "สิงหาคม" : "August";
            month[8] = this.$i18n.locale == 'th' ? "กันยายน" : "September";
            month[9] = this.$i18n.locale == 'th' ? "ตุลาคม" : "October";
            month[10] = this.$i18n.locale == 'th' ? "พฤศจิกายน" : "November";
            month[11] = this.$i18n.locale == 'th' ? "ธันวาคม" : "December";
            let year = parseInt(d.year()) + 543;
            return this.twoDigit(d.date()) + ' ' + month[d.month()] + ' ' + year + ' ' + this.twoDigit(d.hour()) +
                ':' + this.twoDigit(d.minute());
        },
        twoDigit(digit) {
            if (String(digit).length == 1) {
                return "0" + digit;
            } else {
                return digit;
            }
        },
    },
};

export default mixins;