import $axios from "axios";
export default {
  state: {
    loggedIn: false,
    eKYC: {
      IAL: null,
      contact_number: "",
      name_en: "",
      name_th: "",
    },
    default_eKYC: {
      IAL: null,
      contact_number: "",
      name_en: "",
      name_th: "",
    },
    me: null,
    otpRef: null,
    phoneNo: null,
    wallet: null,
    balance: 0
  },
  getters: {
    balance: (state) => {
      return state.balance
    },
    wallet: (state) => {
      return state.wallet;
    },
    eKYC_phone(state) {
      return state.eKYC.contact_number;
    },
    memberLevel(state) {
      if (state.eKYC != null) {
        if (state.eKYC.IAL == 2.3 || state.eKYC.IAL == 1.2) {
          return "gold";
        } else {
          return "silver";
        }
      }
      return "bronze";
    },
  },
  mutations: {
    SET_WALLET(state, data) {
      state.wallet = data;
    },
    SET_LOGIN(state, data) {
      state.loggedIn = data;
    },
    SET_OTPREF(state, data) {
      state.otpRef = data;
    },
    SET_PHONE_AUTH(state, data) {
      state.phoneNo = data;
    },
    SET_EKYC_DATA(state, data) {
      state.eKYC = data;
    },
    SET_LOGOUT(state) {
      state.eKYC = state.default_eKYC;
      state.loggedIn = false;
    },
    SET_USER(state, data) {
      state.me = data;
    },
    SET_BALANCE(state, data) {
      state.balance = data;
    }
  },
  actions: {
    async getMe({
      commit,
      state
    }) {
      try {
        let config = {
          headers: {
            "X-Parse-Application-Id": "928f24ed35d8876dee76d0a5460ef078",
            "X-Parse-Session-Token": localStorage.getItem(
              "sessionToken_casalapin"
            ),
            "content-type": "application/json",
          },
        };
        let result = await $axios.get(
          "https://api.jfin.network/parse/users/me",
          config
        );
        await commit("SET_WALLET", result.data.walletAddress)
        if (state.eKYC.name_th == "" && state.eKYC.name_en == "") {
          await commit("SET_EKYC_DATA", {
            IAL: null,
            contact_number: "",
            name_en: result.data.casa_detail.name_en,
            name_th: result.data.casa_detail.name_th,
          });
        }
      } catch (err) {}
    },
    async getEkycData({
      commit
    }) {
      try {
        const config = {
          headers: {
            "x-parse-session-token": localStorage.getItem(
              "sessionToken_casalapin"
            ),
          },
        };
        let result = await $axios.get("/ekyc_data", config);
        await commit("SET_EKYC_DATA", result.data.data);
        return true;
      } catch (err) {
        return false;
      }
    },
    async getBalance({
      commit,
      getters
    }) {
      try {
        let config = await {
          headers: {
            "X-Parse-Session-Token": localStorage.getItem("sessionToken_casalapin"),
            "parse-session-verify-token": getters.accessToken,
          },
        };
        let result = await $axios.get(
          "https://api.jfin.network/v2/balance/"+ getters.wallet,
          config
        );
        let all = await result.data.Balances;
        let myBalance = await all.find(
          (q) =>
          q.asset_name === "Jfin"
        );
        if (myBalance) {
          await commit("SET_BALANCE", myBalance.balance);
        }
        return 200;
      } catch (err) {
        console.log(err)
        return 400;
      }
    },
  },
};