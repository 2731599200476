
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import $cookies from 'vue-cookies';
Vue.use(VueI18n)

import en from './en';
import th from './th';

const messages = {
  'en': en,
  'th': th
}

var lang = localStorage.getItem('casa_lang');

const i18n = new VueI18n({
  locale: lang ?? 'en', // set locale
  messages //set locale messages
})
export default i18n
